import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Seatmap.module.scss";
import { useOutletContext } from "react-router-dom";
import { RiArrowDownSLine } from "@remixicon/react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Text, useAuth } from "@eventsquare/uikit";

import { EditTypeModal } from "@components/modals/EditTypeModal/EditTypeModal";
import { ChannelsContext } from "@components/ChannelsWrapper";
import { SelectPlaceTypeModal } from "@components/modals/SelectPlaceTypeModal/SelectPlaceTypeModal";

import { Api } from "@lib/api";
import { formatCurrency, getQuantity } from "@lib/helpers";

import {
  ActiveChannelType,
  ChannelSeatmap,
  ChannelShow,
  ChannelType,
} from "@type/channelEdition";
import { EditPriceModal } from "@components/modals/EditPriceModal/EditPriceModal";
import { Currency } from "@type/currency";

interface SeatmapProps {
  seatmapData: ChannelSeatmap | undefined;
  show: ChannelShow | undefined;
}

declare const seatmap: {
  Map: any;
};

interface SeatmapInterface {
  load: (callback?: (data: SeatmapExtraData) => void) => void;
  unmount: () => void;
  selectPlaces: (places: string | string[]) => void;
  deselectPlaces: (places?: string | string[]) => void;
  disablePlaces: (places: string | string[]) => void;
  setTypes: (types: string[]) => void;
  seatmap: unknown;
}

export interface ClickedPlace {
  action: "select" | "deselect";
  place: string;
  area: string | undefined;
  types: { id: string; name: string }[];
}

interface SeatmapExtraData {
  id: string;
  json: string;
  name: string;
  places: {
    disabled: string[];
  };
  svg: null;
  types: SeatmapExtraDataTypes[];
}

interface SeatmapExtraDataTypes {
  areas: string[];
  branding: {
    color: string;
    image: string;
  };
  id: string;
  name: string;
  places: string[];
  price: number;
}

interface ActiveAreaType extends ChannelType {
  showId: string;
}

let map: SeatmapInterface | null = null;

const cx = classNames.bind(styles);

export const Seatmap = (props: SeatmapProps) => {
  const { seatmapData, show } = props;

  const {
    cart,
    setCart,
    setCartIsBeingModified,
    seatmapTypeInCartToEdit,
    setSeatmapTypeInCartToEdit,
  } = useOutletContext<ChannelsContext>();

  const { t } = useTranslation("components/seatmap");

  const { account } = useAuth();

  const [clickedPlace, setClickedPlace] = useState<ClickedPlace | null>(null);
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [activePrice, setActivePrice] = useState<number | null>(null);
  const [showEditPriceModal, setShowEditPriceModal] = useState<boolean>(false);
  const mapActiveFilter = useRef<string | null>(null);
  const mapActivePrice = useRef<number | null>(null);

  const [activeAreaType, setActiveAreaType] = useState<
    ActiveAreaType | undefined
  >(undefined);
  const [showSelectTypeModal, setShowSelectTypeModal] =
    useState<boolean>(false);
  const [isTypesListOpen, setIsTypesListOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!show || !seatmapData) return;
    loadSeatmap();

    return () => {
      if (map) {
        map.unmount();
        map = null;
      }
    };
  }, [show, seatmapData]);

  useEffect(() => {
    if (!map) return;
    if (
      seatmapTypeInCartToEdit &&
      seatmapTypeInCartToEdit.seatmap?.id === seatmapData?.id &&
      seatmapTypeInCartToEdit.show?.id === show?.id &&
      seatmapData?.types
        ?.map((type) => type.id)
        .includes(seatmapTypeInCartToEdit.id)
    ) {
      setActiveFilter(seatmapTypeInCartToEdit.id);
      mapActiveFilter.current = seatmapTypeInCartToEdit.id;
      setActivePrice(seatmapTypeInCartToEdit.price);
      mapActivePrice.current = seatmapTypeInCartToEdit.price;
      applyFilter();
      setSeatmapTypeInCartToEdit(null);
    }
  }, [seatmapTypeInCartToEdit, seatmapData?.types]);

  useEffect(() => {
    // If you select or deselect a place on the seatmap, this function will run
    if (!clickedPlace || !show || !seatmapData || !cart) return;
    // prevent other clicks on other places
    document.body.style.pointerEvents = "none";

    const { place, area, action } = clickedPlace;

    const seatmapTypes = seatmapData.types;
    if (!seatmapTypes) {
      document.body.style.pointerEvents = "auto";
      return;
    }

    if (action === "select") {
      if (area) {
        const clickedType = seatmapTypes.find(
          (type: { id: string }) => type.id === clickedPlace.types[0].id
        );
        const quatity = getQuantity(
          clickedPlace.types[0] as ChannelType,
          cart,
          show.id
        );
        setActiveAreaType({
          ...clickedType,
          showId: show.id,
          quantity: quatity,
        } as ActiveAreaType);
        document.body.style.pointerEvents = "auto";
        return;
      }

      if (clickedPlace.types.length === 0) {
        document.body.style.pointerEvents = "auto";
        return;
      }

      if (clickedPlace.types.length === 1) {
        modifyCart(place, clickedPlace.types[0].id, action);
      } else {
        document.body.style.pointerEvents = "auto";
        setShowSelectTypeModal(true);
      }
    } else {
      const typeId = getTypeFromCartByPlace(clickedPlace.place);
      if (typeId) {
        modifyCart(place, typeId, action);
      } else {
        document.body.style.pointerEvents = "auto";
      }
    }
  }, [clickedPlace]);

  const toggleArrowClass = cx("seatmap__typeToggleArrow", {
    "seatmap__typeToggleArrow--open": isTypesListOpen,
  });

  const typesListClass = cx("seatmap__typesList", {
    "seatmap__typesList--open": isTypesListOpen,
  });

  const getTypeFromCartByPlace = (selectedPlace: string) => {
    if (!cart || !show || !seatmapData) return null;

    const showId = show.id;
    const seatmapId = seatmapData.id;
    const cartItems = cart.items.types.filter((type) => {
      return type.show?.id === showId && type.seatmap?.id === seatmapId;
    });

    const cartItem = cartItems
      .filter((item) => item.seatmap && !!item.seatmap.places.length)
      .find((item) =>
        item.seatmap?.places.find((place) => place.object === selectedPlace)
      );

    return cartItem ? cartItem.id : null;
  };

  const loadSeatmap = useCallback(() => {
    // Check if element seatmap-map exists
    if (!document.getElementById("seatmap-map")) {
      return;
    }

    if (map || !seatmapData) return;

    map = new seatmap.Map({
      el: "seatmap-map",
      apikey: "iRQS6YDlX5NTNKBlq4J5BLMB0JbUPQcj",
      endpoint:
        import.meta.env.VITE_API_ENDPOINT ?? "https://api.eventsquare.io/1.0",
      autoZoom: false,
      map: seatmapData.seatmapid,
      autozoom: true,
      select: (e: object) => {
        setClickedPlace({ ...e, action: "select" } as ClickedPlace);
      },
      deselect: (e: object) => {
        setClickedPlace({ ...e, action: "deselect" } as ClickedPlace);
      },
      error: function (err: unknown) {
        // if (err === "place_disabled" || err === "place_forbidden") {
        //   setSeatmapError(err);
        // }
        console.warn(err);
      },
      // socket: true,
      // socketKey: pusher_key,
    });

    if (!map) return;

    map.load((data) => {
      if (!data) return;
      applyFilter();
      selectPlacesFromCart();
    });
  }, [seatmapData]);

  const applyFilter = () => {
    if (!map?.seatmap) return;

    map.deselectPlaces();
    if (!mapActiveFilter.current) {
      selectPlacesFromCart();
      map.setTypes([]);
    } else {
      selectPlacesFromCart(mapActiveFilter.current);
      map.setTypes([mapActiveFilter.current]);
    }
  };

  useEffect(() => {
    mapActiveFilter.current = activeFilter;
    mapActivePrice.current = activePrice;
    applyFilter();
  }, [activeFilter, activePrice]);

  const selectPlacesFromCart = (
    filteredType: string | undefined = undefined
  ) => {
    if (!map?.seatmap) return;

    const placesInCart = getPlacesFromCart(filteredType);

    if (placesInCart) {
      map.selectPlaces(placesInCart.selected);
      map.disablePlaces(placesInCart.disabled);
      // map.deselectPlaces(placesInCart.disabled);
    }
  };

  const getPlacesFromCart = (filteredType: string | undefined = undefined) => {
    if (!cart || !show || !seatmapData) return;

    const selectedPlacesFromCart: string[] = [];
    const disabledPlacesFromCart: string[] = [];
    let cartTypes = cart.items.types.filter(
      (type) => type.show?.id === show.id && type.seatmap?.id === seatmapData.id
    );

    if (filteredType) {
      cartTypes = cartTypes.filter((type) => type.id === filteredType);
    }

    const filterPrice = mapActivePrice.current;
    cartTypes.forEach((type) =>
      type.seatmap?.places.forEach((place) => {
        if (!filteredType) {
          selectedPlacesFromCart.push(place.object);
        } else {
          type.price === filterPrice
            ? selectedPlacesFromCart.push(place.object)
            : disabledPlacesFromCart.push(place.object);
        }
      })
    );
    return {
      selected: selectedPlacesFromCart,
      disabled: disabledPlacesFromCart,
    };
  };

  const modifyCart = async (
    place: string,
    typeId: string,
    action: "select" | "deselect"
  ) => {
    if (!cart || !show || !seatmapData || !map) return;
    setCartIsBeingModified(true);
    try {
      const data: {
        quantity: number;
        show: string;
        seatmap: string;
        places: string[];
        price?: number;
      } = {
        quantity: action === "select" ? 1 : -1,
        show: show.id,
        seatmap: seatmapData.id,
        places: [place],
      };
      // if action === "deselect"
      // find find the item in the cart and get the price
      if (action === "deselect") {
        const cartItem = cart.items.types.find((type) => {
          return (
            type.id === typeId &&
            type.seatmap?.places.find((p) => p.object === place)
          );
        });

        if (cartItem) {
          data.price = cartItem.price;
        }
      }

      if (action === "select" && activePrice !== null) {
        data.price = activePrice;
      }

      const response = await Api.put(
        `/cart/${cart.cartid}/types/${typeId}`,
        data
      );

      setCart(response.cart);
    } catch (error: unknown) {
      console.error(error);
      switch (action) {
        case "select":
          map.deselectPlaces([place]);
          break;
        case "deselect":
          map.selectPlaces([place]);
          break;
      }
    } finally {
      document.body.style.pointerEvents = "auto";
      setCartIsBeingModified(false);
      setClickedPlace(null);
    }
  };

  const getActiveFilterName = (filter: string): string => {
    if (!seatmapData || !seatmapData.types) return "";
    if (!filter) return t("show_all");
    return (
      seatmapData?.types?.find((type) => type.id === filter)?.name ??
      t("filter_error")
    );
  };

  if (!seatmapData || !seatmapData?.types?.length) return null;

  return (
    <>
      <div className={styles.seatmap}>
        {seatmapData.types.length > 1 && (
          <div className={styles.seatmap__typeSelect}>
            <div className={styles.seatmap__typeSelectHeader}>
              <div>
                <Text variant="h5" noMargin>
                  {getActiveFilterName(activeFilter)}
                </Text>
                {activePrice !== null && (
                  <div>
                    {activePrice
                      ? formatCurrency(
                          activePrice,
                          account?.currency as Currency
                        )
                      : "Gratis"}
                    <button
                      className={styles.seatmap__editPriceButton}
                      onClick={() => setShowEditPriceModal(true)}
                    >
                      Wijzig prijs
                    </button>
                  </div>
                )}
              </div>

              <button
                onClick={() => setIsTypesListOpen(!isTypesListOpen)}
                className={styles.seatmap__typeToggleButton}
              >
                <div className={toggleArrowClass}>
                  <RiArrowDownSLine />
                </div>
              </button>
            </div>
            <ul className={typesListClass}>
              <li
                className={styles.seatmap__typesListItem}
                onClick={() => {
                  setActiveFilter("");
                  setActivePrice(null);
                  setIsTypesListOpen(false);
                }}
                style={{ fontWeight: activeFilter === "" ? "bold" : "normal" }}
              >
                {t("show_all")}
              </li>
              {seatmapData.types.map((type) => {
                return (
                  <li
                    key={type.id}
                    className={styles.seatmap__typesListItem}
                    onClick={() => {
                      setActiveFilter(type.id);
                      setActivePrice(type.price);
                      setIsTypesListOpen(false);
                    }}
                    style={{
                      fontWeight: activeFilter === type.id ? "bold" : "normal",
                    }}
                  >
                    {type.branding.color && (
                      <div
                        className={styles.seatmap__typesListItem_color}
                        style={{ backgroundColor: `${type.branding.color}` }}
                      />
                    )}
                    {type.name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div id="seatmap-map" className={styles.seatmap__map} />
      </div>
      <SelectPlaceTypeModal
        clickedPlace={clickedPlace}
        showModal={showSelectTypeModal}
        closeModal={() => {
          setShowSelectTypeModal(false);
          setClickedPlace(null);
        }}
        deselectPlace={(id: string) => map?.deselectPlaces([id])}
        modifyCart={modifyCart}
      />
      <EditTypeModal
        activeType={activeAreaType as ActiveChannelType}
        closeModal={() => setActiveAreaType(undefined)}
      />
      <EditPriceModal
        activePrice={activePrice ?? 0}
        setActivePrice={setActivePrice}
        showModal={showEditPriceModal}
        closeModal={() => setShowEditPriceModal(false)}
      />
    </>
  );
};
